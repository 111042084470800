import formatDistance from "date-fns/formatDistance";
import locale from "date-fns/locale/en-US";
import format from "date-fns/format";
import formatTz from "date-fns-tz/format";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import parseISO from "date-fns/parseISO";
import formatISO from "date-fns/formatISO";
import previousSunday from "date-fns/previousSunday";
import differenceInMinutes from "date-fns/differenceInMinutes";
import add from "date-fns/add";
import logger from "./logger";

const formatDistanceLocale: Record<string, string> = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}mo",
  xMonths: "{{count}}mo",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

const formatSingleDistanceLocale: Record<string, string> = {
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}mo",
  xMonths: "{{count}}mo",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
};

function formatDistanceDisplay(token: string, count: number) {
  let tokenizedDate = "";
  if (count === 1) {
    tokenizedDate = formatSingleDistanceLocale[token];
  }
  if (!tokenizedDate) {
    tokenizedDate = formatDistanceLocale[token];
  }

  const result = tokenizedDate.replace("{{count}}", count.toString());

  return result;
}

export const relativeDate = (date: string) => {
  try {
    return formatDistance(parseISO(date), new Date(), {
      locale: {
        ...locale,
        formatDistance: formatDistanceDisplay,
      },
    });
  } catch {
    return "";
  }
};

export const timeUntil = (date: string) =>
  differenceInMinutes(parseISO(date), new Date());

export const getFormattedDate = (date: string) => {
  try {
    return format(parseISO(date), "E, h:mm a");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

export const getFormattedFullDate = (date: string) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    const dateUTC = parseISO(date);
    const zoned = utcToZonedTime(dateUTC, tz);
    const val = formatTz(zoned, "EEEE, LLLL do yyyy, 'at' h:mma z", {
      timeZone: tz,
    });
    return val;
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

// ie, "Tue, 11:00pm GMT"
export const getFormattedWeekdayWithTimeAndTimezone = (date: string) => {
  try {
    const tz = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    const parsedTime = parseISO(date);
    const formattedTime = format(parsedTime, "E, h:mma");

    return `${formattedTime.charAt(0) + formattedTime.slice(1).toLowerCase()} ${tz}`;
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

// ie, "Oct 14 7:00pm ET"
export const getFormattedDateWithTimeAndTimezone = (date: Date) => {
  try {
    const tz = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];

    const formattedTime = format(date, "LLL d h:mma");

    return `${formattedTime.charAt(0) + formattedTime.slice(1).toLowerCase()} ${tz}`;
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

// ie, "Sunday"
export const getFormattedWeekday = (date: string) => {
  try {
    return format(parseISO(date), "EEEE");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

// ie, "02/11"
export const getFormattedMonthDay = (date: string) => {
  try {
    return format(parseISO(date), "MM/dd");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

export const getFormattedTime = (date: string) => {
  try {
    return format(parseISO(date), "h:mm b");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

export const getFormattedDateTime = (date: string) => {
  try {
    return format(parseISO(date), "MMMM d, yyyy");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

export const getFormattedDateTimeNoYear = (date: string) => {
  try {
    return format(parseISO(date), "MMM d");
  } catch (error) {
    logger.error("getFormattedDate error", { date, error });
    return "";
  }
};

export const getDateRangeYesterday = (currentDate: Date) => {
  const start = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );
  const end = add(new Date(start), { days: 1, minutes: -1 });

  return {
    start: `${formatISO(start)}`,
    end: `${formatISO(end)}`,
  };
};

export const getDateRangeToday = (currentDate: Date) => {
  const start = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const end = add(new Date(start), { days: 1, minutes: -1 });

  return {
    start: `${formatISO(start)}`,
    end: `${formatISO(end)}`,
  };
};

export const getDateRangeThisWeek = (currentDate: Date) => {
  const start = previousSunday(currentDate);
  const end = add(new Date(currentDate), { hours: 12 });
  return {
    start: `${formatISO(start)}`,
    end: `${formatISO(end)}`,
  };
};

export const getDateRangeLastWeek = (currentDate: Date) => {
  const end = previousSunday(currentDate);
  const start = previousSunday(end);
  return {
    start: `${formatISO(start)}`,
    end: `${formatISO(end)}`,
  };
};

export const getDateRangeByDate = (
  year: number,
  month: number,
  day: number
) => {
  const start = new Date(year, month, day);
  const end = add(start, { days: 1, minutes: -1 });
  return {
    start: `${formatISO(start)}`,
    end: `${formatISO(end)}`,
  };
};

export const formatDateOption = (date: Date) => format(date, "MMMM d, yyyy");
