import "twin.macro";
import styled from "styled-components";

export default styled.svg.attrs((props) => ({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  height: props.height,
  width: props.width,
}))``;
